import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { Gestor } from '@/models/Gestor'
// import router from '../../../Routes/router'
import Swal from 'sweetalert2'
import { gestorTransformer } from '@/utils/transformers'
// import { v4 } from 'uuid'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const LOAD_UNIDADES = 'LOAD_UNIDADES'
export const LOAD_ROLES = 'LOAD_ROLES'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    unidadesNegocio: [],
    roles: [],
    step: 1,
    loading: true,
    isEditing: false,
    dialog: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }, { id }) {
    commit(RESET_STATE)
    commit(SET_STATE, { loading: true, isEditing: false })
    dispatch('form/BOOTSTRAP_FORM', { model: new Gestor() }, { root: true })
    await dispatch(LOAD_UNIDADES)
    await dispatch(LOAD_ROLES)
    if (id) {
      commit(SET_STATE, { isEditing: true })
      await api.getEntidade('gestor', id).then(response => {
        commit(SET_STATE, { loading: false })
        dispatch('form/BOOTSTRAP_FORM', { model: new Gestor(gestorTransformer(response.data[0])) }, { root: true })
      })
    } else {
      commit(SET_STATE, { dialog: true, loading: false, isEditing: false })
    }
  },
  async [LOAD_UNIDADES] ({ dispatch, commit }) {
    await api.listEntidade('unidadeNegocio').then(
      unidadesNegocio => {
        commit(SET_STATE, { unidadesNegocio })
      },
    )
  },
  async [LOAD_ROLES] ({ dispatch, commit }) {
    await api.getEntidade('role', 2).then(
      response => {
        const roles = response.data
        commit(SET_STATE, { roles })
      },
    )
  },
  async [SUBMIT] ({ commit, dispatch, state, rootState }, { router, user }) {
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        const form = { ...rootState.form.data, unidade: rootState.form.data.unidades }
        form.role_id = form.role
        const [day, month, year] = form.data_nascimento.split('/')
        form.data_nascimento = year + '-' + month + '-' + day;
        (() => {
          return state.isEditing
            ? api.salvarEntidade(form, 'gestor', form.id)
            : api.cadastrarEntidade(form, 'gestor')
        })().then(
          data => {
            // this.api.fakeRequest({ resolve: false, data: { message: 'Vixi' } }).then(data => {
            Swal.fire({
              title: state.isEditing
                ? 'Gestor atualizado com sucesso!'
                : 'Gestor cadastrado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            dispatch('gestores/BOOTSTRAP', null, { root: true })
            router.push({
              path: `/${user.get().role}/gestores`,
            })
          },
          error => {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: error.errors ? error.errors : error.message,
            })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
